@import "~@ionic/angular/css/core.css";
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import "~@ionic/angular/css/display.css";
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";

$MAX_MOBILE_WIDTH: 414px;
$SMALL_TABLET_WIDTH: 585px;
$MAX_TABLET_WIDTH: 1024px;

$BOX_SHADOW_CARD: 0px 2px 4px rgba(0, 0, 0, 0.14),
  0px 3px 4px rgba(0, 0, 0, 0.12), 0px 1px 5px rgba(0, 0, 0, 0.2);


// ----------------------------- Utility -----------------------------

.bold {
  font-weight: bold;
}

.loading {
  color: var(--ion-color-dark-100);
  font: 24px var(--ion-font-family);
  text-align: center;
  max-width: 400px;
  margin: 10px auto;
  min-height: 500px;
}

.home-container .swiper-pagination-bullet {
  width: 15px;
  height: 15px;
  @media (max-width: $MAX_MOBILE_WIDTH) {
    width: 10px;
    height: 10px;
  }
}

.company-container .swiper-pagination,.simulation-container .swiper-pagination {
  position: relative;
  padding-top: 10px;
}


// ----------------------------- Cards -----------------------------

.form-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-width: fit-content;
  height: fit-content;
  border-radius: 8px;
  &.login, &.recover-password {
    max-width: 574px;
  }
  &.add-inspiration {
    max-width: 690px;
  }
  @media (max-width: $MAX_MOBILE_WIDTH) {
    box-shadow: none;
  }
  ion-card-header {
    &.recover-card {
      margin-bottom: 30px;
    }
    margin: 60px 23px 50px;
    padding: 0;
    @media (max-width: $MAX_TABLET_WIDTH) {
      margin: 43px 30px;
    }
    @media (max-width: $MAX_MOBILE_WIDTH) {
      margin: 30px 19px;
    }
    ion-card-title {
      text-align: center;
      font-size: 34px;
      font-weight: 400;
      color: var(--ion-color-primary);
      @media (max-width: $MAX_MOBILE_WIDTH) {
        font-size: 24px;
      }
      &.error {
        @media (max-width: $MAX_TABLET_WIDTH) {
          font-size: 24px;
        }
      }
      &.green {
        color:  var(--ion-color-secondary-shade);
        max-width: 375px;
        margin: auto;
      }
    }
    ion-card-subtitle {
      text-align: center;
      font-size: 14px;
      color: var(--ion-color-primary);
      font-weight: 400;
      line-height: 24px;
      margin: 15px 70px 0;
      @media (max-width: $MAX_TABLET_WIDTH) {
        font-size: 14px;
        margin: 15px auto;
      }
    }
  }
  &.user-data {
    margin-bottom: 47px;
    @media (max-width: $MAX_TABLET_WIDTH) {
      margin: 55px 30px;
    }
    @media (max-width: $MAX_MOBILE_WIDTH) {
      margin: auto;
    }
  }
  ion-card-content {
    &.user-data, &.add-inspiration {
      width: 90%;
    }
    @media(max-width: $MAX_MOBILE_WIDTH) {
      width: 100%;
      padding: 0;
    }
  }
  .action-button-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 50px auto;
  }
  .icon-close {
    align-self: flex-end;
    font-size: 20px;
    margin: -15px;
    cursor: pointer;
    @media(max-width: $MAX_MOBILE_WIDTH) {
      display: none;
    }
  }
}

.box {
  box-shadow: 0px 2px 4px rgb(0 0 0 / 25%), 0px 3px 4px rgb(0 0 0 / 12%), 0px 1px 5px rgb(0 0 0 / 20%);
  background-color: var(--ion-color-light);
  border-radius: 8px;
  border: var(--ion-color-medium) dashed;
  max-width: 130px;
  height: 60px;
  margin: auto;
  .text {
    color: var(--ion-color-medium);
    padding: 10px;
    text-align: center;
    font-weight: 900;
    margin: auto;
    &.green {
      color: var(--ion-color-success);
    }
  }
}

// ----------------------------- Buttons -----------------------------
.btn {
  cursor: pointer;
  &:focus {
    outline: none;
  }
  &.primary {
    --box-shadow: none;
    height: 56px;
    width: 300px;
    text-transform: uppercase;
    color: var(--ion-color-primary-shade);
    font-weight: 600;
    font-size: 14px;
    letter-spacing: 0.84px;
    --background: var(--ion-color-secondary-shade);
    --border-radius: 30.5px;
    &[disabled] {
      opacity: 0.3;
      color: var(--ion-color-dark-contrast);
      --background: var(--ion-color-light-contrast);
    }
  }
  &.text {
    --background: none;
    --box-shadow: none;
    --padding-start: none;
    color: var(--ion-color-primary);
    width: fit-content;
    font-weight: 500;
    font-size: 14px;
  }
  &.underline {
    text-decoration: underline;
  }
  &.edit {
    --box-shadow: none;
    overflow: auto;
    color: var(--ion-color-primary-shade);
    --background: var(--ion-color-light);
    --border-radius: 30.5px;
    height: 34px;
    width: 165px;
    text-transform: uppercase;
    font-weight: 500;
    font-size: 14px;
    letter-spacing: 0.84px;
    border: 1px solid var(--color-gray-9);
    border-radius: 30px;
    text-decoration-line: underline;
    &[disabled] {
      opacity: 0.3;
      color: var(--ion-color-dark-contrast);
      --background: var(--ion-color-light-contrast);
    }
  }
  &.danger {
    --border-color: var(--ion-color-danger);
    color: var(--ion-color-danger);
  }
  &.outline {
    --background: none;
    --border-style: solid;
    --border-width: 1px;
  }
  &.small {
    max-width: 190px;
    height: 36px;
    &[disabled] {
      --border-width: 0;
    }
    @media (max-width: $MAX_MOBILE_WIDTH) {
      max-width: 140px;
    }
  }
}

.navigation-buttons-container {
  display: flex;
  justify-content: space-between;
  .button {
    display: flex;
    flex-direction: row;
    align-items: center;
    color: var(--ion-color-primary-blue);
    .btn {
      text-transform: uppercase;
      color: var(--ion-color-primary-blue);
      font-size: 14px;
      font-family: var(--ion-font-family-medium);
      letter-spacing: 0.84px;
    }
    &.back .btn {
      margin-left: 10px;
    }
    &.foward .btn {
      margin-right: 10px;
    }
    ion-icon {
      font-size: 20px;
    }
  }
}


// ----------------------------- Selector -----------------------------

.selector {
  font: 14px var(--ion-font-family-medium);
  color: var(--ion-color-primary-blue);
  text-transform: uppercase;
  line-height: 257%;
  padding-left: 0;
  max-width: 164px;
}

// ----------------------------- Toast -----------------------------

.success-toast {
  --background: var(--ion-color-success-toast);
  --border-radius: 4px;
  --color: var(--ion-color-primary-shade);
  --font-size: 14px;
  --max-width: 510px;
}

.error-toast {
  --background: var(--ion-color-danger-toast);
  --border-radius: 4px;
  --color: var(--ion-color-danger-shade);
  --font-size: 14px;
  --max-width: 510px;
}

.information-toast {
  --background: var(--ion-color-primary-blue);
  --color: var(--ion-color-primary-contrast);
  --font-size: 14px;
  --max-width: 910px;
}

.generic-modal .modal-wrapper, .simulation-modal .modal-wrapper {
  @media (min-width: 770px) {
    height: 550px;
  }
}

.welcome-lite-client .modal-wrapper {
  @media (min-width: 770px) {
    height: 650px;
    width: 1000px;
  }
}

.terms-modal .modal-wrapper {
  @media (min-width: 770px) {
    width: 800px;
    height: 650px;
  }
}

.input-days-modal .modal-wrapper{
  @media (min-width: $SMALL_TABLET_WIDTH) {
    height: 500px;
  }
}

.edit-contractor-modal .modal-wrapper {
  @media (min-width: $SMALL_TABLET_WIDTH) {
    width: 700px;
    height: 700px;
  }
}

.project-modal .modal-wrapper {
  height: 400px;
}

.image-modal .modal-wrapper {
  width: 75%;
  height: 80%;
  box-shadow: unset;
  @media (max-width: $MAX_MOBILE_WIDTH) {
    width: 100%;
    height: 40%;
 }
  background: transparent;
}

.image-modal {
  ion-backdrop {
    --backdrop-opacity: 0.9;
  }
}

.confirm-project-modal .modal-wrapper {
  .actions-buttons {
    display: flex;
    flex-direction: column;
    align-items: center;
    .btn {
      max-width: 166px;
      height: 46px;
    }
  }
  @media (min-width: 770px) {
    height: 580px;
  }
}

.lite-client-account-activation-link-sent-modal .modal-wrapper {
  @media (max-width: 768px) {
    max-width: 800px;
    max-height: 550px;
  }
}

// ----------------------------- Projects cards -----------------------------

.card-grid {
  text-align: center;
  overflow: inherit;
  @media (max-width: $MAX_TABLET_WIDTH) {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    margin-top: 56px;
  }
  @media (max-width: 770px) {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }
  @media (max-width: $SMALL_TABLET_WIDTH) {
    display: flex;
    flex-direction: row;
    margin: 43px 20px 43px;
  }
  margin-top: 79px;
  ion-card {
    box-shadow: $BOX_SHADOW_CARD;
    border-radius: 8px;
    width: 230px;
    height: 220px;
    cursor: pointer;
    @media(max-width: $MAX_MOBILE_WIDTH) { margin: 15px; }
  }
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  ion-card-title {
    font-size: 20px;
    font-weight: 500;
    letter-spacing: 0.15px;
    line-height: 23px;
    margin: auto;
    color: var(--ion-color-dark-light);
  }
  ion-card-content {
    display: flex;
    align-items: center;
    min-height: 70px;
  }
  .image-container {
    border-radius: 10px 10px 0 0;
    display: flex;
    justify-content: center;
    .image {
      height: 152px;
      width: 230px;
      background-size: cover;
      &.inactive { filter: grayscale(1); }
      &.floor {
        background: var(--homein-home-project-floor) no-repeat 100% 100%;
      }
      &.bathroom {
        background: var(--homein-home-project-bathroom) no-repeat 100% 100%;
      }
      &.kitchen {
        background: var(--homein-home-project-kitchen) no-repeat 100% 100%;
      }
      &.terrace {
        background: var(--homein-home-project-terrace) no-repeat 100% 100%;
      }
      &.windows {
        background: var(--homein-home-project-windows) no-repeat 100% 100%;
      }
      &.barbecue {
        background: var(--homein-home-project-barbecue) no-repeat 100% 100%;
      }
      &.ac {
        background: var(--homein-home-project-ac) no-repeat 100% 100%;
      }
      &.garden {
        background: var(--homein-home-project-garden) no-repeat 100% 100%;
      }
      &.parking {
        background: var(--homein-home-project-parking) no-repeat 100% 100%;
      }
      &.restyling {
        background: var(--homein-home-project-restyling) no-repeat 100% 100%;
      }
      &.roller {
        background: var(--homein-home-project-roller) no-repeat 100% 100%;
      }
      &.pool {
        background: var(--homein-home-project-pool) no-repeat 100% 100%;
      }
      &.boiler {
        background: var(--homein-home-project-boiler) no-repeat 100% 100%;
      }
      &.calefont {
        background: var(--homein-home-project-calefont) no-repeat 100% 100%;
      }
    }
  }
}

// ----------------------------- Rating Stars -----------------------------

.star {
  color: var(--ion-color-warning-tint);
}

// ----------------------------- Forms -----------------------------

.form-title {
  @media (max-width: $MAX_TABLET_WIDTH) {
    margin-left: 55px;
  }
}
.form-content {
  .input-password {
    display: flex;
    align-self: normal;
    align-items: baseline;
    .btn-icon {
      cursor: pointer;
      font-size: 20px;
      background-color: transparent;
      opacity: 0.4;
      ion-icon {
        color: var(--ion-color-dark-38);
      }
    }
  }
  .form-section {
    margin: 45px auto;
    &.top {
      margin-top: 30px;
    }
    &.simple-inputs {
      @media (max-width: $MAX_MOBILE_WIDTH) {
         margin: 35px 20px 0 0;
      }
    }
  }
  ion-item {
    --highlight-height: 2px;
    ion-label {
      -webkit-padding-start: 10px;
    }
    input {
      height: 0;
      @media not all and (min-resolution:.001dpcm) {
        height: unset;
      }
      margin-left: 10px;
    }
    input:-webkit-autofill {
      height: 0;
      -webkit-box-shadow: 0 0 0 10px white inset;
      box-shadow: 0 0 0 10px white inset;
      -webkit-text-fill-color: black;
    }
    input:-webkit-autofill:focus {
      height: 0;
      -webkit-box-shadow: 0 0 0 10px white inset;
      box-shadow: 0 0 0 10px white inset;
      -webkit-text-fill-color: black;
    }
    &.icon-input ion-input {
      flex-direction: row-reverse;
      .btn-icon {
        font-size: 20px;
        background-color: transparent;
        opacity: 0.4;
      }
    }
    &.readonly-form-input {
      ion-label {
        opacity: 0.3;
        overflow: visible;
      }
      p {
        margin-left: 9px;
        color: var(--ion-color-dark-300);
      }
    }
    &.checkbox {
      cursor: pointer;
      p {
        font-size: 14px;
        color: var(--ion-color-dark-100);
        margin: auto 10px;
        a {
          font-size: 14px;
          color: black;
          text-decoration: underline;
        }
      }
    }
    .note {
      padding-left: 10px;
      &.disabled {
        opacity: 0.3;
      }
      &:last-of-type {
        padding-bottom: 10px;
      }
    }
  }
  &.error {
    ion-item {
      ion-label {
        color: var(--ion-color-danger);
      }
      ion-input input {
        -webkit-text-fill-color: var(--ion-color-danger);
      }
    }
  }
  &.user-data,
  &.add-inspiration,
  &.register,
  &.change-password {
    .input-box-container {
      &.double {
        display: flex;
        justify-content: space-between;
        ion-item {
          flex-grow: 2;
          width: -webkit-fill-available;
        }
        &.expandable {
          @media (max-width: $MAX_MOBILE_WIDTH) {
            flex-direction: column;
          }
        }
      }
      &.triple {
        display: flex;
        justify-content: space-between;
        ion-item {
          flex-grow: 3;
        }
      }
      .input-alert {
        font-size: 12px;
        margin: 5px auto 5px 25px;
        &.error {
          color: var(--ion-color-danger);
        }
      }
    }
  }
  &.add-inspiration {
    @media (max-width: $MAX_MOBILE_WIDTH) {
      width: 100%;
    }
  }
}
.menu-icon {
  margin-right: 24px;
}
.styled-popover {
  ion-radio {
    --color-checked: var(--ion-color-primary-blue);
    --color: var(--ion-color-primary-blue);
  }
  ion-label,
  ion-item {
    font: 14px var(--ion-font-family);
    --color: var(--ion-color-primary-blue);
    text-transform: uppercase;
    line-height: 257%;
  }
  ::-webkit-scrollbar {
    display: none;
  }
}

// ----------------------------- Data tables -----------------------------


$BREAKPOINT_TABLE_MOBILE: 450px;
$BREAKPOINT_TABLE_TABLET: 770px;

.table {
  display: flex;
  flex-direction: column;
  max-height: 800px;
  overflow-y: auto;
  overflow-x: auto;
  .column-container {
    .table-label {
      color: var(--ion-color-medium);
      font: 10px var(--ion-font-family-medium);
      letter-spacing: 1.5px;
      text-transform: uppercase;
      line-height: 16px;
      &.name {
        max-width: 150px;
        margin-right: 10px;
        @media(max-width: $BREAKPOINT_TABLE_MOBILE) {
          max-width: 130px;
          margin-right: unset
        }
      }
      &.requests {
        max-width: 100px;
      }
      &.price {
        max-width: 120px;
      }
      &.status {
        max-width: 100px;
      }
      &.id {
        max-width: 125px;
      }
      &.clientname {
        margin-left: 25px;
      }
      &.comision {
        max-width: 125px;
      }
      &.icon {
        max-width: 50px;
      }
      &.only-desktop {
        @media(max-width: $BREAKPOINT_TABLE_TABLET) {
          display: none;
        }
      }
    }
  }
  .row-container {
    ion-col {
      padding: 0;
    }
    background-color: var(--ion-color-light);
    align-items: center;
    flex-wrap: nowrap;
    .row-data {
      color: var(--ion-color-dark);
      font: 14px var(--ion-font-family);
      letter-spacing: 0.25px;
      line-height: 20px;
      &.contractor-name {
        max-width: 170px;
        margin-right: 20px;
        &.uppercase {
          text-transform: uppercase;
        }
        @media(max-width: $BREAKPOINT_TABLE_MOBILE) {
          min-width: 150px;
        }
      }
      &.price {
        max-width: 105px;
        margin-right: 30px;
      }
      &.requests {
        max-width: 105px;
      }
      &.status {
        max-width: 105px;
        text-transform: capitalize;
        margin-right: 20px;
      }
      &.clientname {
        margin-left: 20px;
      }
      &.comision {
        max-width: 125px;
      }
      &.icon {
        max-width: 50px;
      }
      &.only-desktop {
        @media(max-width: $BREAKPOINT_TABLE_TABLET) {
          display: none;
        }
      }
    }
    ion-icon {
      width: 24px;
      height: 24px;
      color: var(--ion-color-primary-blue);
      &.no-margin {
        margin-left: 0;
      }
      @media(max-width: $BREAKPOINT_TABLE_TABLET) {
        margin-left: 50px;
      }
      @media(max-width: $BREAKPOINT_TABLE_MOBILE) {
        margin-left: 10px;
      }
    }
  }
  .row-container:nth-child(odd) {
    background-color: var(--ion-color-primary-contrast);
  }
}

.empty-catalog {
  text-align: center;
}

// ----------------------------- Modals -----------------------------

ion-modal {
  --border-radius: 16px;
  @media (max-width: $BREAKPOINT_TABLE_TABLET) {
    --border-radius: 0;
  }
}

// TODO: check all the above css and refactor below

// ----------------------------- Ionic overrides -----------------------------

ion-input {
  --placeholder-color: var(--color-gray-8) !important;
  input {
    height: auto !important;
  }
}

ion-item {
  &.ion-invalid {
    &.ion-touched {
      ion-label {
        color: var(--ion-color-danger) !important;
      }
    }
  }
}

// ----------------------------- Buttons -----------------------------

.new-btn { // TODO: rename to btn when refactor is completed
  --border-radius: 30.5px;
  --box-shadow: none;
  cursor: pointer;
  font-size: 16px;
  font-weight: 600;
  height: 56px;
  text-transform: none;
  width: 300px;
  &:focus {
    outline: none;
  }
  &.primary {
    --background: var(--ion-color-secondary-shade);
    color: var(--ion-color-primary-shade);
  }
  &.secondary {
    --background: var(--color-light);
    --border-color: var(--color-gray-d);
    --border-style: solid;
    --border-width: 1px;
    color: var(--color-gray-7);
  }
  &.small {
    height: 36px;
    width: 200px;
  }
  &[disabled] {
    opacity: 0.5;
  }
}

.option-btn {
  align-items: center;
  background: var(--color-light);
  border: solid 1px var(--color-gray-d);
  border-radius: 14px;
  color: var(--color-gray-7);
  cursor: pointer;
  display: flex;
  font-size: 14px;
  font-weight: 600;
  height: 56px;
  justify-content: center;
  letter-spacing: 0.84px;
  text-transform: uppercase;
  width: 300px;
  &.selected {
    background: var(--ion-color-primary-shade);
    border: none;
    color: var(--color-light);
  }
}

// ----------------------------- Cards -----------------------------

.project-cards-container {
  display: flex;
  flex-wrap: wrap;
  .project-card {
    background-color: var(--color-white);
    border: 1px solid var(--color-gray-e8);
    border-radius: 8px;
    box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.1);
    margin-bottom: 16px;
    width: 100%;
    transition: 200ms;
    &.clickable {
      cursor: pointer;
      &:hover {
        box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.15);
      }
    }
    .project-card-section {
      border-top: 1px solid var(--color-gray-e8);
      padding: 16px;
      &:first-of-type {
        border-top: none;
      }
      .project-card-header {
        align-items: center;
        display: flex;
        justify-content: space-between;
        margin-bottom: 16px;
        .project-card-header-title {
          color: var(--color-gray-3);
          font-size: 24px;
          margin: 0;
        }
        .project-card-header-action {
          background-color: var(--color-white);
          border: 1px solid var(--color-gray-d);
          border-radius: 24px;
          color: var(--color-gray-7);
          cursor: pointer;
          display: flex;
          font-size: 16px;
          justify-content: center;
          padding: 8px 16px;
          transition: 200ms;
          &:hover {
            background-color: var(--color-gray-f0);
          }
        }
      }
      .project-card-body {
        display: flex;
        flex-direction: column;
        &:first-of-type {
          border: none;
        }
        &.two-column {
          flex-direction: row;
          .project-card-body-left {
            width: 60%;
          }
          .project-card-body-right {
            width: 40%;
          }
        }
        .project-card-text {
          color: var(--color-gray-6);
          font-size: 14px;
          margin-top: 16px;
          &:first-of-type {
            margin-top: 0;
          }
          &.lg {
            color: var(--color-gray-3);
            font-size: 18px;
            margin-top: 4px;
          }
          &.xl {
            color: var(--color-gray-3);
            font-size: 22px;
            margin-top: 4px;
          }
          &.link {
            color: var(--ion-color-primary-blue);
            cursor: pointer;
            display: block;
            margin-top: 16px;
            &:last-of-type {
              margin-bottom: 16px;
            }
          }
        }
        .project-card-button {
          background-color: var(--color-white);
          border: 1px solid var(--color-gray-d);
          border-radius: 32px;
          color: var(--color-gray-7);
          cursor: pointer;
          display: inline-block;
          font-size: 16px;
          font-weight: bold;
          margin-top: 16px;
          padding: 12px 24px;
          text-decoration: none;
          transition: 200ms;
          width: fit-content;
          &:hover {
            background-color: var(--color-gray-f0);
          }
        }
      }
      .project-card-options {
        display: flex;
        justify-content: space-evenly;
        .project-card-option-button {
          background-color: var(--color-secondary-light);
          border-radius: 32px;
          color: var(--ion-color-primary-shade);
          cursor: pointer;
          font-size: 16px;
          font-weight: bold;
          padding: 20px;
          text-decoration: none;
          transition: 200ms;
          width: 260px;
          &:hover {
            background-color: var(--ion-color-secondary-shade);
          }
          &:disabled {
            color: var(--color-gray-7);
            cursor: initial;
            background-color: var(--color-gray-c);
          }
          &.secondary {
            background-color: var(--color-white);
            border: 1px solid var(--color-gray-d);
            color: var(--color-gray-7);
            &:hover {
              background-color: var(--color-gray-f0);
            }
          }
        }
      }
    }
  }
}

@media (max-width: 720px) {
  .project-cards-container {
    .project-card {
      .project-card-section {
        .project-card-header {
          flex-direction: column;
          .project-card-header-title {
            text-align: center;
          }
          .project-card-header-action {
            margin-top: 16px;
          }
        }
        .project-card-body {
          &.two-column {
            flex-direction: column;
            .project-card-body-left {
              width: 100%;
            }
            .project-card-body-right {
              margin-top: 32px;
              width: 100%;
            }
          }
        }
        .project-card-options {
          flex-direction: column;
          .project-card-option-button {
            margin-top: 12px;
            width: 100%;
            &:first-of-type {
              margin-top: 0;
            }
          }
        }
      }
    }
  }
}

// ----------------------------- Container -----------------------------

@import 'src/theme/variables';

.main-container {
  background-color: var(--color-light-shade);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 100%;
  width: 100%;
  .page-container {
    align-items: center;
    display: flex;
    flex-direction: column;
    padding: 32px;
    .page-content {
      align-items: flex-start;
      display: flex;
      margin: 0 auto;
      max-width: 1440px;
      width: 100%;
      .side-menu-container {
        margin-right: 24px;
      }
      .content-container {
        max-width: 1136px;
        width: 100%;
        section {
          .header {
            align-items: center;
            display: flex;
            justify-content: space-between;
            margin-bottom: 16px;
            .header-title {
              align-items: center;
              display: flex;
              font-size: 30px;
              justify-content: flex-start;
              margin: 0;
              .header-back-button {
                align-items: center;
                background-color: var(--color-white);
                border: 1px solid var(--color-gray-d);
                border-radius: 16px;
                cursor: pointer;
                display: flex;
                height: 32px;
                justify-content: center;
                margin-right: 16px;
                transition: 200ms;
                width: 32px;
                &:hover {
                  background-color: var(--color-gray-f0);
                }
                ion-icon {
                  color: var(--color-gray-7);
                  font-size: 22px;
                }
              }
            }
            .header-action {
              background-color: var(--color-secondary-light);
              border-radius: 32px;
              color: var(--ion-color-primary-shade);
              cursor: pointer;
              font-size: 16px;
              font-weight: bold;
              padding: 12px 24px;
              text-decoration: none;
              transition: 200ms;
              width: fit-content;
              &:hover {
                background-color: var(--ion-color-secondary-shade);
              }
            }
          }
          .section-subtitle {
            color: var(--color-gray-6);
            font-size: 24px;
            margin: 0 0 12px;
          }
          .empty-state-container {
            display: flex;
            flex-direction: column;
            align-items: center;
            margin: 64px auto;
            max-width: 480px;
            .empty-state-illustration {
              height: 200px;
              img {
                height: 100%
              }
            }
            .empty-state-description {
              color: #666666;
              font-size: 18px;
              margin: 48px 0;
              text-align: center;
            }
            .empty-state-action {
              background-color: var(--ion-color-secondary-shade);
              border-radius: 32px;
              color: var(--ion-color-primary-shade);
              cursor: pointer;
              font-size: 20px;
              font-weight: bold;
              padding: 20px 40px;
              text-decoration: none;
              transition: 200ms;
              width: fit-content;
              &:hover {
                background-color: var(--color-secondary-light);
              }
            }
          }
        }
      }
    }
  }
}


@media (max-width: 1040px) {
  .main-container {
    .page-container {
      flex-direction: column;
      .page-content {
        flex-direction: column;
        .content-container {
          margin-top: 32px;
        }
      }
    }
  }
}

@media (max-width: 720px) {
  .main-container {
    .page-container {
      .page-content {
        .content-container {
          section {
            .header {
              align-items: flex-start;
              flex-direction: column;
              .header-action {
                margin-top: 16px;
              }
            }
          }
        }
      }
    }
  }
}

@media (max-width: 480px) {
  .main-container {
    .page-container {
      padding: 16px;
      .page-content {
        align-items: center;
        .side-menu-container {
          margin-right: 0;
        }
      }
    }
  }
}

ion-datetime {
  color: var(--ion-color-primary);
}

.integral-remodeling-section {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  .integral-remodeling-title {
    color: #666666;
    font-size: 20px;
    font-weight: bold;
  }
  .integral-remodeling-card {
    background: #fafbfb;
    border-radius: 12px;
    border: 1px solid #cfcfcf;
    display: flex;
    max-width: 480px;
    &:hover {
      cursor: pointer;
    }
    .integral-remodeling-card-image {
      background-image: url(assets/backgrounds/restyling.jpeg);
      background-position: center center;
      background-repeat: no-repeat;
      background-size: cover;
      border-top-left-radius: 10px;
      border-bottom-left-radius: 10px;
      flex-shrink: 0;
      width: 40%;
    }
    .integral-remodeling-card-content {
      display: flex;
      flex-direction: column;
      padding: 12px 16px 8px;
      text-align: left;
      .integral-remodeling-card-title {
        color: #333333;
        font-size: 18px;
        font-weight: bold;
        margin: 0 0 8px;
      }
      .integral-remodeling-card-body {
        color: #666666;
        font-size: 14px;
        margin: 0;
      }
      .btn {
        &.primary {
          align-items: center;
          align-self: flex-end;
          display: flex;
          height: 40px;
          justify-content: center;
          width: 40px;
          --padding-end: 0;
          --padding-start: 0;
        }
      }
    }
  }
}

@media (max-width: 480px) {
  .integral-remodeling-section {
    .integral-remodeling-card {
      flex-direction: column;
      .integral-remodeling-card-image {
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
        border-bottom-left-radius: 0;
        height: 150px;
        width: 100%;
      }
    }
  }
}

.socovesa-toledo-contact-modal, .socovesa-avellanos-contact-modal, .santander-credit-card-contact-modal {
  --min-height: 640px;
  @media (min-width: 480px) {
    --max-width: 400px;
  }
}

.santander-credit-card-contact-modal {
  --min-height: 520px;
}

// ----------------Fonts----------------

$MOBILE_WIDTH_BREAKPOINT: 414px;
$TABLET_WIDTH_BREAKPOINT: 585px;
$DESKTOP_WIDTH_BREAKPOINT: 1024px;

@font-face {
  font-family: "Roboto-Black";
  src: url("/assets/fonts/Roboto-Black.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Roboto-Bold";
  src: url("/assets/fonts/Roboto-Bold.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Roboto-Medium";
  src: url("/assets/fonts/Roboto-Medium.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Roboto-Regular";
  src: url("/assets/fonts/Roboto-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

:root {
  /** primary **/
  --ion-color-primary: #2B3B50;
  --ion-color-primary-rgb: 43,59,80;
  --ion-color-primary-contrast: #ffffff;
  --ion-color-primart-contrast-60: rgba(255, 255, 255, 0.6);
  --ion-color-primary-contrast-rgb: 255,255,255;
  --ion-color-primary-shade: #263446;
  --ion-color-primary-tint: #404f62;
  --ion-color-primary-blue: #3880FF;
  --ion-color-primary-sidebar: rgba(43, 59, 80, 0.5);
  --ion-color-primary-sidebar-highlight: rgba(43, 59, 80, 0.75);
  --ion-color-primary-sidebar-border: #596575;
  --ion-color-primary-blue-contrast-rgba: rgba(76, 141, 255, 0.12);
  --ion-color-primary-shade-blue: #3171E0;
  --ion-color-primary-blue-contraste-12: #4C8DFF1F;

  /** secondary **/
  --ion-color-secondary: #33FFBC;
  --ion-color-secondary-rgb: 51,255,188;
  --ion-color-secondary-contrast: #000000;
  --ion-color-secondary-contrast-rgb: 0,0,0;
  --ion-color-secondary-shade: #2de0a5;
  --ion-color-secondary-tint: #47ffc3;
  --ion-color-secondary-background: #8BFFD9;

  /** tertiary **/
  --ion-color-tertiary: #B1B3B6;
  --ion-color-tertiary-rgb: 177,179,182;
  --ion-color-tertiary-contrast: #000000;
  --ion-color-tertiary-contrast-rgb: 0,0,0;
  --ion-color-tertiary-shade: #9c9ea0;
  --ion-color-tertiary-tint: #b9bbbd;


  /** success **/
  --ion-color-success: #2dd36f;
  --ion-color-success-rgb: 45, 211, 111;
  --ion-color-success-contrast: #ffffff;
  --ion-color-success-contrast-rgb: 255, 255, 255;
  --ion-color-success-shade: #28ba62;
  --ion-color-success-tint: #42d77d;
  --ion-color-success-toast: #BEECDD;
  --ion-color-succes-contrast-20-rgba: rgba(51, 255, 188, 0.2);

  /** warning **/
  --ion-color-warning: #ffc409;
  --ion-color-warning-rgb: 255, 196, 9;
  --ion-color-warning-contrast: #000000;
  --ion-color-warning-contrast-rgb: 0, 0, 0;
  --ion-color-warning-shade: #e0ac08;
  --ion-color-warning-tint: #ffca22;
  --ion-color-warning-contrast-20-rgba: rgba(255, 196, 9, 0.2);

  /** danger **/
  --ion-color-danger: #eb445a;
  --ion-color-danger-rgb: 235, 68, 90;
  --ion-color-danger-contrast: #ffffff;
  --ion-color-danger-contrast-rgb: 255, 255, 255;
  --ion-color-danger-shade: #cf3c4f;
  --ion-color-danger-tint: #ed576b;
  --ion-color-danger-toast: #F8BCC4;
  --ion-color-danger-contrast-rgba: rgba(237, 87, 107, 0.12);

  /** dark **/
  --ion-color-dark: #222428;
  --ion-color-dark-100: #666666;
  --ion-color-dark-200: #343434;
  --ion-color-dark-300: #000000de;
  --ion-color-dark-rgb: 34, 36, 40;
  --ion-color-dark-contrast: #ffffff;
  --ion-color-dark-contrast-rgb: 255, 255, 255;
  --ion-color-dark-shade: #1e2023;
  --ion-color-dark-tint: #383a3e;
  --ion-color-dark-light: rgba(0, 0, 0, 0.87);
  --ion-color-dark-gray: rgb(0 0 0 / 20%);
  --ion-color-gray-rgba: rgba(0, 0, 0, 0.54);
  --ion-color-dark-38: rgba(0, 0, 0, 0.38);
  --ion-color-dark-54: rgba(0, 0, 0, 0.54);

  /** medium **/
  --ion-color-medium: #92949c;
  --ion-color-medium-rgb: 146, 148, 156;
  --ion-color-medium-contrast: #ffffff;
  --ion-color-medium-contrast-rgb: 255, 255, 255;
  --ion-color-medium-shade: #808289;
  --ion-color-medium-tint: #9d9fa6;
  --ion-color-medium-tint-100: #777777;

  /** light **/
  --ion-color-light: #f4f5f8;
  --ion-color-light-rgb: 244, 245, 248;
  --ion-color-light-contrast: #000000;
  --ion-color-light-contrast-rgb: 0, 0, 0;
  --ion-color-light-shade: #d7d8da;
  --ion-color-light-shade-50: rgba(215, 216, 218, 0.5);
  --ion-color-light-tint: #f5f6f9;
  --ion-color-light-blue-20:  rgba(80, 143, 255, 0.2);
  --ion-colo-semi-light-black: rgba(64, 79, 98, 0.2);

  --ion-color-toggle: #3171E0;

  --ion-font-family: "Roboto-Regular";
  --ion-font-family-bold: "Roboto-Bold";
  --ion-font-family-medium: "Roboto-Medium";
  --ion-font-family-black: "Roboto-Black";

  /** Refactored colors **/
  --color-dark: #0000000;
  --color-danger: #e25959;
  --color-danger-contrast: #ffe9e7;
  --color-success: #00a42e;
  --color-success-contrast: #d8f5db;
  --color-warning: #b48105;
  --color-warning-contrast: #fff8db;

  --color-white: #ffffff;
  --color-gray-2: #222222;
  --color-gray-3: #333333;
  --color-gray-4: #444444;
  --color-gray-5: #555555;
  --color-gray-6: #666666;
  --color-gray-7: #777777;
  --color-gray-8: #888888;
  --color-gray-9: #999999;
  --color-gray-a: #aaaaaa;
  --color-gray-b: #bbbbbb;
  --color-gray-c: #cccccc;
  --color-gray-d: #dddddd;
  --color-gray-de: #dedede;
  --color-gray-e: #eeeeee;
  --color-gray-e8: #e8e8e8;
  --color-gray-f0: #f0f0f0;

  --color-light: #fffefe;
  --color-light-shade: #fafbfb;

  --color-secondary-shade: #2de0a5;
  --color-secondary-light: #37eaae;

// ----------------Icons----------------
  .i-no-repeat { background-repeat: no-repeat; }
  .i-location { @extend .i-no-repeat; background-image: url(../assets/icon/icon-location.svg); }
  .i-calendar { @extend .i-no-repeat; background-image: url(../assets/icon/icon-calendar.svg); }

// ----------------Images----------------

  --i-homein-logo: url(../assets/icon/homein-logo-color.svg);
  --i-homein-logo-small: url(../assets/icon/homein-logo-color-small.svg);

  --i-homein-clipboard: url(../assets/icon/icon-clipboard.svg);
  --i-homein-house: url(../assets/icon/icon-house.svg);
  --i-homein-home-search: url(../assets/icon/icon-search.svg);
  --i-homein-home-finance: url(../assets/icon/icon-finance.svg);
  --i-homein-home-contractor: url(../assets/icon/icon-contractor.svg);
  --i-homein-home-support: url(../assets/icon/icon-support.svg);

  --i-homein-how-it-works-approval: url(../assets/icon/approval.svg);
  --i-homein-how-it-works-briefing: url(../assets/icon/briefing.svg);
  --i-homein-how-it-works-inspection: url(../assets/icon/inspection.svg);
  --i-homein-how-it-works-project: url(../assets/icon/project.svg);
  --i-homein-how-it-works-reception: url(../assets/icon/reception.svg);

  --i-homein-dashboard-client-quote: url(../assets/icon/icon-quote.svg);

  --i-homein-companies-evoluciona-logo: url(../assets/icon/evoluciona-logo.svg);
  --i-homein-companies-fuenzalida-logo: url(../assets/icon/fuenzalida-logo.png);
  --i-homein-companies-bci-logo: url(../assets/icon/bci-logo.png);
  --i-homein-companies-scotiabank-logo: url(../assets/icon/logo-scotia-access.svg);
  --i-homein-companies-consorcio-logo: url(../assets/logos/consorcio.svg);
  --i-homein-companies-santander-logo: url(../assets/icon/santander-logo.png);
  --i-homein-companies-century-logo: url(../assets/icon/century-logo.png);
  --i-homein-companies-ebco-logo: url(../assets/icon/ebco-logo.svg);
  --i-homein-companies-magnolia-logo: url(../assets/icon/magnolia-logo.png);
  --i-homein-companies-ruta-capital-logo: url(../assets/icon/irc-logo.png);
  --i-homein-companies-ssilva-logo: url(../assets/icon/ssilva-logo.svg);
  --i-homein-companies-sip-logo: url(../assets/icon/sip-logo.png);
  --i-homein-companies-isa-corp-logo: url(../assets/icon/isa-corp-logo.svg);
  --i-homein-companies-uno-logo: url(../assets/icon/uno-logo.jpg);
  --i-homein-companies-bo-logo: url(../assets/icon/bo-logo.png);
  --i-homein-companies-renval-logo: url(../assets/icon/renval.png);
  --i-homein-companies-indesa-logo: url(../assets/icon/indesa.png);
  --i-homein-companies-makler-logo: url(../assets/icon/makler-logo.png);
  --i-homein-companies-viel-logo: url(../assets/icon/viel-logo.jpg);
  --i-homein-companies-nucleos-logo: url(../assets/icon/nucleos-logo.png);

  --i-homein-customer-service: url(../assets/icon/customer-service.svg);
  --i-homein-dial: url(../assets/icon/dial.svg);
  --i-homein-calculations: url(../assets/icon/calculations.svg);

  --homein-login-background-admin: url(../assets/backgrounds/login-admin.jpg);
  --homein-login-background-client: url(../assets/backgrounds/login-client.jpg);
  --homein-login-background-contractor: url(../assets/backgrounds/login-contractor.jpg);
  --homein-login-background-client-tablet: url(../assets/backgrounds/login-client-tablet.jpg);
  --homein-onboarding-background-client: url(../assets/backgrounds/onboarding-client.jpg);

  // General purpose financing
  --homein-home-general-purpose-financing-banner-mobile: url(../assets/backgrounds/general-purpose-financing-banner-mobile.jpg);
  --homein-home-general-purpose-financing-banner-tablet: url(../assets/backgrounds/general-purpose-financing-banner-tablet.jpg);
  --homein-home-general-purpose-financing-banner-desktop: url(../assets/backgrounds/general-purpose-financing-banner-desktop.jpg);

  --homein-home-background: url(../assets/backgrounds/home-image.jpeg);
  --homein-home-2-background: url(../assets/backgrounds/home2-image-desktop.jpg);
  --homein-home-3-background: url(../assets/backgrounds/home3-image-destkop.jpg);
  --homein-home-background-tablet: url(../assets/backgrounds/home-image-tablet.jpeg);
  --homein-home-background-mobile: url(../assets/backgrounds/home-image-mobile.jpeg);

  // BCI
  --homein-home-bci-banner-mobile: url(../assets/backgrounds/banner-bci-mobile.jpg);
  --homein-home-bci-banner-tablet: url(../assets/backgrounds/banner-bci-tablet.jpg);
  --homein-home-bci-banner-desktop: url(../assets/backgrounds/banner-bci-desktop.jpg);

  // Santander
  --homein-home-santander-banner-mobile: url(../assets/backgrounds/banner-santander-mobile.jpg);
  --homein-home-santander-banner-tablet: url(../assets/backgrounds/banner-santander-tablet.jpg);
  --homein-home-santander-banner-desktop: url(../assets/backgrounds/banner-santander-desktop.jpg);

  // Scotia
  --homein-home-scotia-banner-mobile: url(../assets/backgrounds/banner-scotia-mobile.jpg);
  --homein-home-scotia-banner-tablet: url(../assets/backgrounds/banner-scotia-tablet.jpg);
  --homein-home-scotia-banner-desktop: url(../assets/backgrounds/banner-scotia-desktop.jpg);

  // CREDIT CARD
  --homein-home-credit-card-banner-mobile: url(../assets/backgrounds/banner-credit-card-mobile.jpg);
  --homein-home-credit-card-banner-tablet: url(../assets/backgrounds/banner-credit-card-tablet.jpg);
  --homein-home-credit-card-banner-desktop: url(../assets/backgrounds/banner-credit-card-desktop.jpg);


  --homein-home-project-floor: url(../assets/backgrounds/floor.jpg);
  --homein-home-project-bathroom: url(../assets/backgrounds/bathroom.jpg);
  --homein-home-project-kitchen: url(../assets/backgrounds/kitchen.jpg);
  --homein-home-project-terrace: url(../assets/backgrounds/terrace.jpg);
  --homein-home-project-windows: url(../assets/backgrounds/windows.jpg);
  --homein-home-project-barbecue: url(../assets/backgrounds/barbecue.jpg);
  --homein-home-project-ac: url(../assets/backgrounds/ac.jpeg);
  --homein-home-project-garden: url(../assets/backgrounds/garden.jpeg);
  --homein-home-project-parking: url(../assets/backgrounds/parking.jpeg);
  --homein-home-project-greensolutions: url(../assets/backgrounds/green-solutions.jpeg);
  --homein-home-project-restyling: url(../assets/backgrounds/restyling.jpeg);
  --homein-home-project-roller: url(../assets/backgrounds/roller.jpg);
  --homein-home-project-pool: url(../assets/backgrounds/pool.jpg);
  --homein-home-project-boiler: url(../assets/backgrounds/boiler.jpg);
  --homein-home-project-calefont: url(../assets/backgrounds/calefont.jpg);
  --i-homein-home-logo-chc: url(../assets/icon/chc-contractor.svg);
  --i-homein-home-logo-dellorto: url(../assets/icon/dellorto-contractor.svg);
  --i-homein-home-logo-fuegos: url(../assets/backgrounds/fuegos.jpeg);
  --i-homein-home-logo-mk: url(../assets/icon/mk-contractor.svg);
  --i-homein-home-logo-panorama: url(../assets/backgrounds/panorama.png);
  --i-homein-home-logo-piscineria: url(../assets/backgrounds/piscineria.png);
  --i-homein-home-logo-rollux: url(../assets/backgrounds/rollux.png);
  --i-homein-home-logo-toproller: url(../assets/backgrounds/toproller.jpeg);
  --i-homein-home-logo-varvacoa: url(../assets/backgrounds/varvacoa.png);
  --i-homein-home-client-experience-one: url(../assets/icon/benjamin-ramirez.jpeg);
  --i-homein-home-client-experience-two: url(../assets/icon/pareja-benjamin.jpeg);
  --i-homein-home-client-experience-three: url(../assets/icon/patricia-allende.jpeg);
  --simulation-home-image-1: url(../assets/backgrounds/home-image-1.png);
  --simulation-home-image-2: url(../assets/backgrounds/home-image-2.png);
  --simulation-home-image-3: url(../assets/backgrounds/home-image-3.png);
  --simulation-apartment-image-1: url(../assets/backgrounds/apartment-image-1.png);
  --simulation-apartment-image-2: url(../assets/backgrounds/apartment-image-2.png);
  --simulation-apartment-image-3: url(../assets/backgrounds/apartment-image-3.png);

  --pdf-icon: url(../assets/icon/pdf-icon.svg);

  --referral-banner-desktop: url(../assets/backgrounds/referral-banner-desktop.jpg);
  --referral-banner-tablet: url(../assets/backgrounds/referral-banner-tablet.jpg);
  --referral-banner-mobile: url(../assets/backgrounds/referral-banner-mobile.jpg);

  --referral-banner-desktop-second: url(../assets/backgrounds/referral-banner-desktop-second.jpg);
  --referral-banner-tablet-second: url(../assets/backgrounds/referral-banner-tablet-second.jpg);
  --referral-banner-mobile-second: url(../assets/backgrounds/referral-banner-mobile-second.jpg);
}
